import Vue from "vue";
import App from "./App.vue";

import "normalize-css";
import VueFormulate from "@braid/vue-formulate";

Vue.config.productionTip = false;
Vue.use(VueFormulate);
Vue.prototype.$helpers = require("./lib/helpers")

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

new Vue({
  render: (h) => h(App),
}).$mount("#app");
