const dataProducts = require("./dataProducts");

const config = require("./config");
const calculateOrder = require("./calculateOrder");

const VALIDATE_MISSING_ARGUMENTS = "INCOMPLETE-ARGUMENTS";
const VALIDATE_NO_COLOR = "NO-COLOR";
const VALIDATE_INVALID_AMOUNT = "INVALID-AMOUNT";

const calculate = ({
    amount,
    garmentId,
    garmentFabric,
    colorsFront,
    colorsBack,
    colorsNeck,
    colorsSleeve,
    sellingPricePerGarment,
}) => {
    return new Promise((resolve, reject) => {

        //check local data
        // const check = dataCheck(garmentId);

        //must give these variables
        if (!amount || !garmentId || !sellingPricePerGarment || !garmentFabric)
            reject({
                message: "arguments missing",
                validation: VALIDATE_MISSING_ARGUMENTS,
            });

        //check if the garment can be found
        if (!dataProducts[garmentId])
            reject({ message: "can not find garmentId: '" + garmentId + "'", });

        //convert to numbers from possible strings
        amount = parseInt(amount);
        colorsFront = parseInt(colorsFront);
        colorsBack = parseInt(colorsBack);
        colorsNeck = parseInt(colorsNeck);
        colorsSleeve = parseInt(colorsSleeve);
        sellingPricePerGarment = parseFloat(sellingPricePerGarment);

        //must at least print one color
        if (colorsFront + colorsBack + colorsNeck + colorsSleeve <= 0) {
            reject({
                message: "need at least one printing color",
                validation: VALIDATE_NO_COLOR,
            });
        }

        //must print minimum amount and not more
        if (amount < config.AMOUNT_MIN && amount > config.AMOUNT_MAX) {
            reject({
                message: `amount needs to be between min:${config.AMOUNT_MIN} and max:${config.AMOUNT_MAX}`,
                validation: VALIDATE_INVALID_AMOUNT,
            });
        }
        /**
         * calculate desired price
         */
        const requested = calculateOrder({
            amount,
            garmentId,
            garmentFabric,
            colorsFront,
            colorsBack,
            colorsNeck,
            colorsSleeve,
            sellingPricePerGarment,
        });

        /**
         * calculate minimum price for 10 items
         */
        const minSalePriceMinAmount =
            (calculateOrder({
                    amount: config.AMOUNT_MIN,
                    garmentId,
                    garmentFabric,
                    colorsFront,
                    colorsBack,
                    colorsNeck,
                    colorsSleeve,
                    sellingPricePerGarment,
                }).costProduction *
                config.TAX) /
            config.AMOUNT_MIN;

        /**
         * calculate minimum price for current amount
         */
        const minSalePriceReqAmount =
            (calculateOrder({
                    amount,
                    garmentId,
                    garmentFabric,
                    colorsFront,
                    colorsBack,
                    colorsNeck,
                    colorsSleeve,
                    sellingPricePerGarment,
                }).costProduction *
                config.TAX) /
            amount;

        const isValid =
            sellingPricePerGarment >= minSalePriceMinAmount ? true : false;

        const result = {
            ...requested,
            minSalePriceMinAmount: parseFloat(minSalePriceMinAmount.toFixed(2)),
            minSalePriceReqAmount: parseFloat(minSalePriceReqAmount.toFixed(2)),
            sellingPricePerGarment: sellingPricePerGarment,
            isValid,
        }

        //remove sensitive data    
        delete result.costProduction;
        delete result.costFabricPerItem;
        delete result.costPrintingPerItem;
        delete result.costHandelingPerItem;
        delete result.costFixed;
        // console.log(result)

        resolve(result);
    });
};

// const dataCheck = () => {
//   const handlingKeys = Object.keys(dataHandeling)//.map(h => h.toLocaleLowerCase())

//   const notFound = []

//   dataFabric.forEach(f => {
//     if (!handlingKeys.find((h) => h == f.name/*.toLowerCase()*/)) {
//       notFound.push(f.name)
//     }
//   })

//   return { valid: notFound.length ? false : true, message: notFound.length ? "data files don't match, could not find: " + notFound.toString() : "ok" }
// }

module.exports = {
    calculate,
    config,
    VALIDATE_NO_COLOR,
    VALIDATE_INVALID_AMOUNT,
    VALIDATE_MISSING_ARGUMENTS,
};


//testing
// (async () => {


//   var result = await calculate({
//     amount: "196",
//     colorsBack: 0,
//     colorsFront: "2",
//     colorsNeck: 0,
//     colorsSleeve: 0,
//     garmentFabric: "color",
//     garmentId: "creator-t-shirt",
//     payout: 1000,
//     sellingPricePerGarment: "30"
//   })

//   console.table(result)
// })()