const config = require('./config')
const dataProducts = require('./dataProducts');
const GARMENT_PICTURE_FORMAT = "_100x100"

let data = null;

const load = async () => {
    return fetch(config.URL_PRODUCTS).then(res => {
        return res.json()

    }).then(data => {

        const items = Object.keys(dataProducts)
        const ps = Object.keys(config.PRINT_SURFACES)

        //only adopt products that are in local data (the reference)
        //to make sure the 2 data sets match (now they don't :/)
        const filtered = data.products.filter(p => {
            const foundItem = items.find(i => {
                return (i.toLowerCase() == p.handle.toLowerCase())
            })

            if (!foundItem) console.error(`can not match "${p.handle}" with client data`)

            return foundItem;
        })
        //parse the array
        const parsed = filtered.map(p => {
            const name = p.title;
            const id = p.handle;
            //remove rubish tags, result in array of only PRINT_SURFACE definitions
            const printSurfaces = p.tags.filter(t => ps.find((psKey) => config.PRINT_SURFACES[psKey].toLowerCase() == t.toLowerCase())).map(s => s.toLowerCase())

            const garmentImages = p.images.map(i => {
                try {
                    return parseSrcUrl(i.src)
                } catch {
                    console.error("can not parse name or swatch", i)
                    return null
                }
            })
            //"https://cdn.shopify.com/s/files/1/0488/5273/0024/products/Creator_SpecialHeather_Mid_Heather_Royal_Blue_Packshot_Front_Main_0.jpg?v=1610054765"

            return {
                ...dataProducts[id],// add original productData
                name,
                id,
                printSurfaces,
                garmentImages
            }
        })

        data = parsed;

        return parsed;

    }).catch(err => {
        console.log("data products failed", err)
    })
}

function parseSrcUrl(url) {
    const DELIMITER = "_";
    const GROUPS = ["garmentName", "garmentFabric", "colorName"];
    const indexOfExtension = url.lastIndexOf(".")
    const indexOfFileName = url.lastIndexOf("/")
    const groups = url.substr(0, indexOfExtension).substr(indexOfFileName).split(DELIMITER);
    if (groups.length >= GROUPS.length) {

        var o = GROUPS.reduce((acc, curr, index) => {
            acc[curr] = groups[index];
            return acc;
        }, {})

        //from: "EssentialHeather" to "essenatial-heather"
        const parsedGarmentFabric = o.garmentFabric.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join('-').toLowerCase();
        //change the src url to point to a smaller version
        return { garmentFabric: parsedGarmentFabric, colorName: o.colorName.toLowerCase(), src: url.toString().replace(".jpg", GARMENT_PICTURE_FORMAT + ".jpg") }
    }

    //this src is badly formated
    console.error("cannot parse badly formated src:", url)
    return null;
}

module.exports = {
    load,
    data
};