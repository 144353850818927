<template>
  <div class="calculator">
    <!-- <div class="devPanel">
      <span v-html="objectToHtmlString(result)"></span>
    </div> -->

    <transition name="fade">
      <div v-if="!garments" class="loading">
        <div class="loadingSpinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <FormulateForm action="" class="form" v-else>
        <div class="formSectionGroup">
          <section class="formSection">
            <h3>preferences</h3>

            <div class="twoOnARow">
              <FormulateInput
                :options="garmentNameList"
                v-model="formValues.garmentId"
                type="select"
                placeholder="Select an option"
                label="garment"
                name="garmentId"
              />

              <FormulateInput
                :options="garmentFabricList"
                v-model="formValues.garmentFabric"
                type="select"
                placeholder="Select an option"
                label="fabric"
                name="garmentFabric"
              />
            </div>

            <div
              v-if="garmentColorList && garmentColorList.length"
              class="formulate-input"
            >
              <div class="formulate-input-label formulate-input-label--before">
                colour
              </div>
              <div class="garmentTiles">
                <button
                  class="garmentTile"
                  v-for="gc in garmentColorList"
                  :key="gc.src"
                  v-tooltip.top-center="gc.colorName.split('-').join(' ')"
                  :class="{ selected: selectedTile == gc }"
                  v-on:click="selectedTile = gc"
                  :style="{ backgroundImage: `url('${gc.src})` }"
                >
                  <!-- <img :src="gc.src" alt="" srcset="" class="garmentImage" /> -->
                </button>
              </div>
            </div>
            <div class="twoOnARow">
              <FormulateInput
                :options="colorsList"
                v-model="formValues.colorsFront"
                type="select"
                placeholder="select an option"
                name="colorsFront"
                :error="validationOfColor()"
                label="print front"
                :disabled="
                  !hasPrintSurface(
                    fabricCalculator.config.PRINT_SURFACES.PRINT_FRONT
                  )
                "
              />

              <FormulateInput
                :options="colorsList"
                v-model="formValues.colorsBack"
                type="select"
                placeholder="select an option"
                name="colorsBack"
                :error="validationOfColor()"
                label="print back"
                :disabled="
                  !hasPrintSurface(
                    fabricCalculator.config.PRINT_SURFACES.PRINT_BACK
                  )
                "
              />
            </div>
            <div class="twoOnARow">
              <FormulateInput
                :options="colorsList"
                v-model="formValues.colorsNeck"
                type="select"
                placeholder="select an option"
                name="colorsNeck"
                :error="validationOfColor()"
                label="print neck"
                :disabled="
                  !hasPrintSurface(
                    fabricCalculator.config.PRINT_SURFACES.PRINT_NECK
                  )
                "
              />

              <FormulateInput
                :options="colorsList"
                v-model="formValues.colorsSleeve"
                type="select"
                placeholder="select an option"
                name="colorsSleeve"
                :error="validationOfColor()"
                label="print sleeve"
                :disabled="
                  !hasPrintSurface(
                    fabricCalculator.config.PRINT_SURFACES.PRINT_SLEEVE
                  )
                "
              />
            </div>

            <h3>Your goal</h3>

            <p></p>
            <FormulateInput
              type="number"
              name="sellingPricePerGarment"
              v-model="formValues.sellingPricePerGarment"
              label="How much do you want to sell these items for?"
              :help="
                !validation
                  ? `The minimum sales price for ${fabricCalculator.config.AMOUNT_MIN} items is ${result.minSalePriceMinAmount} Euro`
                  : ''
              "
              min="1"
              max="100"
              :error="
                !result.isValid && !validation ? 'The price is too low' : null
              "
              error-behavior="live"
            />

            <div class="customSlider">
              <div class="customSlider-wrap formulate-input">
                <div class="formulate-input-label">
                  How many garments you aim to sell?
                </div>
                <FormulateInput
                  type="range"
                  :min="fabricCalculator.config.AMOUNT_MIN"
                  :max="fabricCalculator.config.AMOUNT_MAX"
                  value="45"
                  step="10"
                  v-model="formValues.amount"
                />
                <ul class="customSlider-scale">
                  <li
                    v-for="a in [
                      fabricCalculator.config.AMOUNT_MIN,
                      250,
                      500,
                      750,
                      fabricCalculator.config.AMOUNT_MAX,
                    ]"
                    v-bind:key="a"
                  >
                    <span
                      class="customSlider-btn"
                      @click="formValues.amount = a.toString()"
                      >{{ a }}</span
                    >
                  </li>
                </ul>
              </div>

              <FormulateInput
                type="number"
                v-model="formValues.amount"
                :min="fabricCalculator.config.AMOUNT_MIN"
                :max="fabricCalculator.config.AMOUNT_MAX"
                step="10"
              />
            </div>
          </section>
        </div>
        <div class="formSectionGroup">
          <section class="formSection">
            <h3>estimated earnings</h3>

            <h1>
              €{{
                $helpers.formatPrice(result.isValid ? result.earnings : "0")
              }}
            </h1>

            <div class="feedback">
              <div v-if="result.isValid">
                <img alt="" src="../assets/icon-check.svg" />
                <h3>Great, <br />we can print this!</h3>
              </div>
              <div v-else>
                <img alt="" src="../assets/icon-cross.svg" />
                <h3>Woops, no go... <br />check your campaign settings</h3>
              </div>
            </div>

            <p>
              * This calculator is only designed for screenprinted items.
              <a href="mailto:hello@fabricmerch.com">Send us a request</a> for
              embroidered items. The calculation is an estimation and is never
              binding.
            </p>
            <!-- <p>
            <b
              >The minimum sales price for 10 items is
              {{ formatPrice(result.minSalePriceMinAmount) }} Euro</b
            >
          </p> -->
          </section>
          <!-- <section class="formSection">
          <h3>Insight</h3>
          <ul class="priceList">
            <li class="priceListHead">
              <span>aantal</span>
              <span>earnings / item</span>
              <span>earnings</span>
            </li>
            <li v-for="i in insightList" :key="i.amount">
              <span>{{ i.amount }}</span>
              <span>{{
                i.isValid ? "€" + formatPrice(i.earningsPerItem) : " - "
              }}</span>
              <span>{{
                i.isValid ? "€" + formatPrice(i.earnings) : " - "
              }}</span>
            </li>
          </ul>
        </section> -->
        </div>
      </FormulateForm>
    </transition>
  </div>
</template>

<script>
import fabcalc from "@/lib/fabric-calculator/fabricCalculator";
import dataProducts from "@/lib/fabric-calculator/dataProducts";
import garments from "@/lib/fabric-calculator/garments";
import debounce from "lodash.debounce";

export default {
  name: "Calculator",
  data() {
    return {
      result: {},
      validation: null,
      listValues: [10, 20, 50, 100, 250],
      insightList: {},
      formValues: {
        colorsFront: 1,
        colorsBack: 0,
        colorsNeck: 0,
        colorsSleeve: 0,
        sellingPricePerGarment: 50,
        amount: 30,
        garmentId: null,
        garmentFabric: null,
        payout: 1000,
      },
      colorsList: [
        { label: "none", value: "0" },
        { label: "1 color", value: "1" },
        { label: "2 colors", value: "2" },
        { label: "3 colors", value: "3" },
        { label: "4 colors", value: "4" },
        { label: "5 colors", value: "5" },
        { label: "6 colors", value: "6" },
      ],
      garments: null,
      selectedTile: null,
    };
  },
  created() {},
  mounted() {
    garments.load().then((res) => {
      this.garments = res;
      this.formValues.garmentId = this.garmentNameList[0].value;
      this.debouncedCalc();
    });
  },
  watch: {
    formValues: {
      deep: true,
      handler() {
        this.debouncedCalc();
      },
    },
    "formValues.garmentId": {
      handler() {
        //reset the fabriccolor of the garment
        this.formValues.garmentFabric = Object.keys(
          this.garments.find((g) => g.id == this.formValues.garmentId).fabric
        )[0];

        //reset printing colors
        this.formValues.colorsFront = 1;
        this.formValues.colorsBack = 0;
        this.formValues.colorsNeck = 0;
        this.formValues.colorsSleeve = 0;

        this.selectedTile = null;
      },
    },
    "formValues.garmentFabric": {
      handler() {
        this.selectedTile = null;
      },
    },
    result() {},
  },
  computed: {
    garmentNameList() {
      return this.garments
        .map((g) => ({
          value: g.id,
          label: g.brand + " - " + g.name,
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
    },
    garmentFabricList() {
      return Object.keys(dataProducts[this.formValues.garmentId].fabric)
        .sort()
        .map((k) => ({ value: k, label: k.split("-").join(" ") }));
    },
    garmentColorList() {
      /* if (
        !this.formValues.garmentId ||
         this.formValues.garmentFabric != "color"
       ) {
         return null;
       }
      */
      const swatches = this.garments.find(
        (g) => g.id === this.formValues.garmentId
      ).garmentImages;

      //return swatches for current garmentFabric type
      return swatches.filter(
        (s) =>
          s.garmentFabric.toLowerCase().replace(/\s/g, "") ===
          this.formValues.garmentFabric.toLowerCase().replace(/\s/g, "")
      );
    },
    fabricCalculator() {
      return fabcalc;
    },
  },
  methods: {
    debouncedCalc: debounce(function () {
      this.validation = null;
      fabcalc
        .calculate(this.formValues)
        .then((res) => {
          this.result = res;
          /* disabled for now
          //generate the insights list
          Promise.all(
            this.listValues.map((v) => {
              return fabcalc.calculate({
                ...this.formValues,
                amount: v,
              });
            })
          ).then((results) => {
            this.insightList = results.reduce(
              (o, r) => ({ ...o, [r.amount]: r }),
              {}
            );
          });

          */
        })
        .catch((res) => {
          this.result = {};
          this.insightList = {};
          this.validation = res.validation;
        });
    }, 100),

    validationOfColor() {
      return this.validation === fabcalc.VALIDATE_NO_COLOR
        ? "at least one color is needed"
        : null;
    },
    objectToHtmlString(o) {
      if (!Object.keys(o).length) return "";
      return Object.keys(o)
        .map((k) => {
          return "<div>" + k + ": " + o[k] + "</div>";
        })
        .reduce((acc, cur) => acc + cur);
    },

    hasPrintSurface(printSurface) {
      // this.garments/*
      const garment = this.garments.find((g) => {
        if (g.id === this.formValues.garmentId) {
          return true;
        }
      });

      //the garment is not defined in external data
      if (!garment || !garment.printSurfaces.length) return false;

      return garment.printSurfaces.find((ps) => ps == printSurface);
    },
  },
};
</script>

<style lang="scss" scoped>
.devPanel {
  position: fixed;
  top: 0;
  left: 0;

  font-size: 12px;
  line-height: 1.3;
  color: gold;
  padding: 4px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
}
.form {
  display: flex;
  flex-wrap: wrap;
}

.formSection {
  padding: 20px;
  background-color: white;
  border: 1px solid $color-grey-lighten1;
  margin: 10px 10px 20px 10px;
  @include m {
    padding: 40px;
  }
}

.formSectionGroup {
  width: 100%;
  @include l {
    flex-grow: 1;
    flex-basis: 40%;
  }
}

.form-element {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dbdbdb;

  display: block;
  width: 100%;
  height: 41px;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.5;
  color: #2e2e2e;
  background-color: #fff;
}

.customSlider {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div[data-classification="text"] {
    width: 9rem;
    margin-left: 2rem;
  }

  div[data-classification="slider"] {
    // width: 100%;
    margin: 0 1.7rem;
    // margin: 0;
  }

  &-help {
    display: block;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.1em;
  }

  &-wrap {
    width: 100%;
  }

  &-scale {
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
    font-size: 1.2rem;
    li {
      margin: 0;
      padding: 0;
      width: 3.4rem;
      text-align: center;
    }
  }
  &-btn {
    cursor: pointer;
    display: block;
    font-weight: bold;
    width: 100%;
    background: black;
    color: white;
  }
}

.twoOnARow {
  @include sm {
    display: flex;
    & > .formulate-input {
      width: 100%;
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.priceList {
  display: block;
  padding: 0;
  & > li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-lighten1;
    padding: 10px;
    &:last-child {
      border-bottom: none;
    }
    & > * {
      flex-basis: 33%;
    }
  }
}

.priceListHead {
  font-size: 12px;
}

.feedback {
  // text-align: center;
  & > div {
    display: flex;
  }
  img {
    width: 100%;
    max-width: 60px;
    margin-right: 20px;
  }
}

.garmentTiles {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  justify-content: left;
}

.garmentTile {
  position: relative;
  border: 1px solid $color-grey-lighten1;
  margin: 0 5px 5px 0;
  cursor: pointer;
  transition: border-color 0.1s;
  width: 40px;
  height: 40px;
  // overflow: hidden;
  &.selected {
    border-color: $color-grey;
    &:before {
      border: 7px solid #fff;
    }
  }
  background-size: 1200%; // item "tote bag" needs lots of scaling
  background-position: center;

  &:before {
    transition: border 0.1s;
    content: " ";
    position: absolute;
    // z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0px solid #fff;
  }
}

//full width
.form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
}

.loading {
  width: 100px;
  text-align: center;
  padding-top: 20rem;
  margin: 0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s 0.4s;
}

.fade-enter,
.fade-leave-to {
  transition: opacity 0.3s;
  opacity: 0;
}
</style>
