<template>
  <div id="app">
    <header class="mainheader">
      <img alt="fabric logo" src="./assets/fabric-black.svg" />
    </header>

    <main>
      <header class="pageHeader">
        <h1>Campaign Calculator</h1>
        <p>
          This calculator is here for you to use and to estimate your profits
          based on the number of items sold. <br />The more you sell, the higher
          your profits will be.
        </p>
        <p>
          <a href="https://www.fabricmerch.com/pages/garments" target="_blank">
            You can see all the available garments here
          </a>
        </p>
      </header>

      <div class="mainContent">
        <div class="mainContent-inner">
          <Calculator />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Calculator from "@/components/Calculator";
export default {
  name: "App",
  components: {
    Calculator,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@900&display=swap");

* {
  box-sizing: border-box;
  outline: 0;
  &::selection {
    color: white;
    background: #1c1b1b;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: tondo, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  background: #fff;
  height: 100%;
}

p {
  color: $color-font;
}

a {
  color: #000;
  &:hover,
  &:visited {
    color: #000;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Libre Franklin", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 2.5rem;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mainheader {
  background: #fff;
  padding: 3rem;
}

.pageHeader {
  text-align: center;
  background: $color-background;
  padding-bottom: 2rem;
  padding-top: 1px;
  p {
    font-style: italic;
    padding: 0 20px;
    @include m {
      width: 80%;
      max-width: 1200px;
      margin: 1rem auto;
    }
  }
}

.mainContent {
  position: relative;
  background: white;
  .mainContent-inner {
    position: relative;
    // margin-top: 2rem;
    z-index: 999;
    @include m {
      padding: 0 2rem;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 8rem;
    // bottom: 2rem;
    display: block;
    background: $color-background;
  }
}

.loadingSpinner {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 10px;

  div {
    position: absolute;
    // top: 33px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 0px;
    animation: loadingSpinner1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 0px;
    animation: loadingSpinner2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 20px;
    animation: loadingSpinner2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 40px;
    animation: loadingSpinner3 0.6s infinite;
  }
}
@keyframes loadingSpinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadingSpinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loadingSpinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    // border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
