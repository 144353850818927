module.exports = {
  "1": {
    "10": 1.76,
    "21": 1.36,
    "51": 1,
    "101": 0.8,
    "251": 0.64,
    "501": 0.56,
    "1001": 0.48
  },
  "2": {
    "10": 2.32,
    "21": 1.76,
    "51": 1.32,
    "101": 1.04,
    "251": 0.88,
    "501": 0.8,
    "1001": 0.72
  },
  "3": {
    "10": 2.88,
    "21": 2.16,
    "51": 1.64,
    "101": 1.28,
    "251": 1.12,
    "501": 1.04,
    "1001": 0.96
  },
  "4": {
    "10": 3.28,
    "21": 2.56,
    "51": 1.92,
    "101": 1.52,
    "251": 1.36,
    "501": 1.28,
    "1001": 1.2
  },
  "5": {
    "10": 4,
    "21": 2.96,
    "51": 2.16,
    "101": 1.72,
    "251": 1.6,
    "501": 1.52,
    "1001": 1.44
  },
  "6": {
    "10": 4.56,
    "21": 3.36,
    "51": 2.6,
    "101": 1.88,
    "251": 1.84,
    "501": 1.76,
    "1001": 1.68
  }
}