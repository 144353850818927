module.exports = {
  "rocker-t-shirt": {
    "brand": "Stanley & Stella",
    "type": "T-shirts",
    "fabric": {
      "color": {
        "staffel": {
          "1": 5.42,
          "50": 4.17,
          "100": 4.03,
          "250": 3.89
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 6.26,
          "50": 4.82,
          "100": 4.65,
          "250": 4.49
        }
      },
      "white": {
        "staffel": {
          "1": 5.03,
          "50": 3.87,
          "100": 3.74,
          "250": 3.61
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "creator-t-shirt": {
    "brand": "Stanley & Stella",
    "type": "T-shirts",
    "fabric": {
      "color": {
        "staffel": {
          "1": 7.7,
          "50": 5.93,
          "100": 5.73,
          "250": 5.53
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 8.58,
          "50": 6.6,
          "100": 6.38,
          "250": 6.16
        }
      },
      "white": {
        "staffel": {
          "1": 7.12,
          "50": 5.48,
          "100": 5.29,
          "250": 5.11
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "changer-sweater": {
    "brand": "Stanley & Stella",
    "type": "Crewneck",
    "fabric": {
      "color": {
        "staffel": {
          "1": 28.18,
          "50": 21.68,
          "100": 20.95,
          "250": 20.23
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 29.93,
          "50": 23.03,
          "100": 22.26,
          "250": 21.49
        }
      },
      "white": {
        "staffel": {
          "1": 28.18,
          "50": 21.68,
          "100": 20.95,
          "250": 20.23
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 2.5,
        "20": 3.35,
        "50": 4.2,
        "100": 4.6,
        "250": 5
      }
    }
  },
  "drummer-sweater": {
    "brand": "Stanley & Stella",
    "type": "Hooded Sweater",
    "fabric": {
      "color": {
        "staffel": {
          "1": 24.16,
          "50": 18.59,
          "100": 17.97,
          "250": 17.35
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 25.84,
          "50": 19.88,
          "100": 19.21,
          "250": 18.55
        }
      },
      "white": {
        "staffel": {
          "1": 24.16,
          "50": 18.59,
          "100": 17.97,
          "250": 17.35
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 3,
        "20": 4,
        "50": 5,
        "100": 5.5,
        "250": 6
      }
    }
  },
  "cruiser-sweater": {
    "brand": "Stanley & Stella",
    "type": "Hooded Sweater",
    "fabric": {
      "color": {
        "staffel": {
          "1": 34.91,
          "50": 26.85,
          "100": 25.96,
          "250": 25.06
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 36.86,
          "50": 28.35,
          "100": 27.41,
          "250": 26.46
        }
      },
      "white": {
        "staffel": {
          "1": 34.91,
          "50": 26.85,
          "100": 25.96,
          "250": 25.06
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 3,
        "20": 4,
        "50": 5,
        "100": 5.5,
        "250": 6
      }
    }
  },
  "expresser-t-shirt": {
    "brand": "Stanley & Stella",
    "type": "T-shirts",
    "fabric": {
      "color": {
        "staffel": {
          "1": 7.12,
          "50": 5.48,
          "100": 5.29,
          "250": 5.11
        }
      },
      "white": {
        "staffel": {
          "1": 6.73,
          "50": 5.18,
          "100": 5,
          "250": 4.83
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "jazzer-t-shirt": {
    "brand": "Stanley & Stella",
    "type": "T-shirts",
    "fabric": {
      "color": {
        "staffel": {
          "1": 5.4,
          "50": 4.16,
          "100": 4.02,
          "250": 3.88
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 6.22,
          "50": 4.79,
          "100": 4.63,
          "250": 4.47
        }
      },
      "white": {
        "staffel": {
          "1": 5.01,
          "50": 3.86,
          "100": 3.73,
          "250": 3.6
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "fuser": {
    "brand": "Stanley & Stella",
    "type": "T-shirts",
    "fabric": {
      "color": {
        "staffel": {
          "1": 9.75,
          "50": 7.5,
          "100": 7.25,
          "250": 7
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 10.82,
          "50": 8.33,
          "100": 8.05,
          "250": 7.77
        }
      },
      "white": {
        "staffel": {
          "1": 8.68,
          "50": 6.68,
          "100": 6.45,
          "250": 6.23
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "shifts-dry-longsleeve": {
    "brand": "Stanley & Stella",
    "type": "Longsleeves",
    "fabric": {
      "color": {
        "staffel": {
          "1": 17.45,
          "50": 13.43,
          "100": 12.98,
          "250": 12.53
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 19.01,
          "50": 14.63,
          "100": 14.14,
          "250": 13.65
        }
      },
      "white": {
        "staffel": {
          "1": 16.28,
          "50": 12.53,
          "100": 12.11,
          "250": 11.69
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "roller-crewneck": {
    "brand": "Stanley & Stella",
    "type": "Crewneck",
    "fabric": {
      "color": {
        "staffel": {
          "1": 19.89,
          "50": 15.3,
          "100": 14.79,
          "250": 14.28
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 21.18,
          "50": 16.29,
          "100": 15.75,
          "250": 15.2
        }
      },
      "white": {
        "staffel": {
          "1": 19.89,
          "50": 15.3,
          "100": 14.79,
          "250": 14.28
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 2.5,
        "20": 3.35,
        "50": 4.2,
        "100": 4.6,
        "250": 5
      }
    }
  },
  "tote-bag": {
    "brand": "Stanley & Stella",
    "type": "Bags",
    "fabric": {
      "color": {
        "staffel": {
          "1": 5.46,
          "50": 4.2,
          "100": 4.06,
          "250": 3.92
        }
      },
      "essential-heather": {
        "staffel": {
          "1": 5.46,
          "50": 4.2,
          "100": 4.06,
          "250": 3.92
        }
      },
      "white": {
        "staffel": {
          "1": 5.46,
          "50": 4.2,
          "100": 4.06,
          "250": 3.92
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 1.8,
        "50": 2,
        "100": 2.2,
        "250": 2.5
      }
    }
  },
  "light-tote-bag": {
    "brand": "Stanley & Stella",
    "type": "Bags",
    "fabric": {
      "color": {
        "staffel": {
          "1": 3,
          "50": 2.31,
          "100": 2.23,
          "250": 2.16
        }
      },
      "white": {
        "staffel": {
          "1": 2.71,
          "50": 2.09,
          "100": 2.02,
          "250": 1.95
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 1.8,
        "50": 2,
        "100": 2.2,
        "250": 2.5
      }
    }
  },
  "king-crew-neck-basic-sweater": {
    "brand": "B&C Collection",
    "type": "Crewneck",
    "fabric": {
      "white": {
        "staffel": {
          "1": 18.58,
          "50": 14.3,
          "100": 13.82,
          "250": 13.34
        }
      },
      "color": {
        "staffel": {
          "1": 18.58,
          "50": 14.3,
          "100": 13.82,
          "250": 13.34
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 2.5,
        "20": 3.35,
        "50": 4.2,
        "100": 4.6,
        "250": 5
      }
    }
  },
  "heavy-cotton-t-shirt": {
    "brand": "Gildan",
    "type": "T-shirts",
    "fabric": {
      "white": {
        "staffel": {
          "1": 3.37,
          "50": 2.6,
          "100": 2.51,
          "250": 2.42
        }
      },
      "color": {
        "staffel": {
          "1": 4.15,
          "50": 3.2,
          "100": 3.09,
          "250": 2.98
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  },
  "heavy-cotton-hooded-sweater": {
    "brand": "Gildan",
    "type": "Hooded Sweater",
    "fabric": {
      "white": {
        "staffel": {
          "1": 16.48,
          "50": 12.68,
          "100": 12.25,
          "250": 11.83
        }
      },
      "color": {
        "staffel": {
          "1": 16.48,
          "50": 12.68,
          "100": 12.25,
          "250": 11.83
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 3,
        "20": 4,
        "50": 5,
        "100": 5.5,
        "250": 6
      }
    }
  },
  "ultra-cotton-longsleeve": {
    "brand": "Gildan",
    "type": "Longsleeves",
    "fabric": {
      "white": {
        "staffel": {
          "1": 8.66,
          "50": 6.66,
          "100": 6.44,
          "250": 6.22
        }
      },
      "color": {
        "staffel": {
          "1": 10.8,
          "50": 8.31,
          "100": 8.03,
          "250": 7.76
        }
      }
    },
    "handeling": {
      "staffel": {
        "1": 1.5,
        "20": 2,
        "50": 2.5,
        "100": 2.75,
        "250": 3
      }
    }
  }
}