const config = require("./config");
const dataProducts = require("./dataProducts");
const dataPrint = require("./dataPrint");
const { roundLikeShopify } = require("../helpers")

const calculateOrder = ({
    amount,
    garmentId,
    garmentFabric,
    colorsFront,
    colorsBack,
    colorsNeck,
    colorsSleeve,
    sellingPricePerGarment,
}) => {
    const colors = [colorsFront, colorsBack, colorsNeck, colorsSleeve];

    /**
     * 1 textielkost
     * volgens staffel
     */

    const g = dataProducts[garmentId]

    const costFabricPerItem =
        g.fabric[garmentFabric].staffel[
        "" +
        Object.keys(g.fabric[garmentFabric].staffel)
            .map((s) => parseInt(s))
            .sort((a, b) => b - a)
            .filter((s) => {
                return amount >= s;
            })[0]
        ];

    /**
     * 2) voorbereidend werk
     * aantal kleur x 30Euro
     */

    const costFixed =
        colorsFront * config.COST_FIXED_PER_COLOR +
        colorsBack * config.COST_FIXED_PER_COLOR +
        colorsNeck * config.COST_FIXED_PER_COLOR +
        colorsSleeve * config.COST_FIXED_PER_COLOR

    /**
     * drukkost
     * wit textiel: aantal stuks * prijs in tabel
     * gekleurd textiel: aantal stuks * (0,25Euro + prijs in tabel)
     */

    const costPrintingPerItem = colors
        .map((colorCount) => {
            if (colorCount == 0) return 0;
            const staffel = dataPrint[colorCount];

            const pricePerPrint =
                staffel[
                "" +
                Object.keys(staffel)
                    .map((s) => parseInt(s))
                    .sort((a, b) => b - a)
                    .filter((staffelKey) => {
                        return amount >= staffelKey;
                    })[0]
                ];

            // anything that is not "white" is regarded as color
            return (garmentFabric.toLowerCase().trim() != "white"
                ? config.COST_SURPLUS_COLORED_FABRIC_PRINTING
                : 0) +
                pricePerPrint;
        })
        .reduce((acc, cur) => acc + cur);

    /**
     * 4) handeling
     */

    //would not advice looping the sellingPricePErGarrment back into the costHandeling
    // const costHandeling = amount * sellingPricePerGarment * config.COST_FIXED_HANDELING;

    const staffelHandeling = dataProducts[garmentId].handeling.staffel;

    const costHandelingPerItem =
        staffelHandeling["" +
        Object.keys(staffelHandeling)
            .sort((a, b) => b - a)
            .filter((staffelKey) => amount >= staffelKey)[0]]

    /**
     * final computations
     */
    const costProduction = roundLikeShopify(roundLikeShopify(amount * roundLikeShopify(costFabricPerItem + costHandelingPerItem + costPrintingPerItem)) + costFixed);

    //    console.log("costProduction", costProduction)
    // console.log("test_costProducionPerItem", test_costProducionPerItem)
    // const test_costProduction = roundLikeShopify(roundLikeShopify(costProduction / amount) * amount)

    // console.log("test_costProduction", costProduction, "vs", test_costProduction)

    const sellingPrice = roundLikeShopify(roundLikeShopify(sellingPricePerGarment / config.TAX) * amount);



    /**
     * the following way and order of calculating earnings 
     * mimicks/results in the same decimals outcome of the shoppify tool 
     */
    let earnings = roundLikeShopify(sellingPrice - costProduction);
    earnings = roundLikeShopify(roundLikeShopify(amount * sellingPricePerGarment / config.TAX) - costProduction)
    earnings = earnings < 0 ? 0 : earnings;
    const earningsPerItem = roundLikeShopify(earnings / amount);
    earnings = roundLikeShopify(earningsPerItem * amount)

    const result = {
        costProduction,
        costFabricPerItem,
        costFixed,
        costPrintingPerItem,
        costHandelingPerItem,
        sellingPrice,
        earnings,
        earningsPerItem,
    };

    Object.keys(result).forEach(
        (k) => (result[k] = parseFloat(result[k].toFixed(2)))
    );

    return { ...result, amount };
}



module.exports = calculateOrder;