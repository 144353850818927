module.exports = {
    AMOUNT_MIN: 10,
    AMOUNT_MAX: 1000,
    TAX: 1.21,
    COST_FIXED_PER_COLOR: 30,
    COST_SURPLUS_COLORED_FABRIC_PRINTING: 0.25,

    PRINT_SURFACES: {
        PRINT_FRONT: "print-front",
        PRINT_BACK: "print-back",
        PRINT_NECK: "print-neck",
        PRINT_SLEEVE: "print-sleeve",
    },

    URL_PRODUCTS: "https://www.fabricmerch.com/collections/garments/products.json?limit=250",
}